import React from 'react';
import i18n from 'i18n-js';
import styled from 'styled-components';
import { useApolloClient, useQuery } from '@apollo/client';
import { LABELS_MINIMAL_QUERY } from 'client-lib';
import { ChipSelect } from '../../lib/patterns';
import type { OptionType } from '../../elements/Select/AsyncSelect/types';
import type { Label } from '../../utils/helpers/types';

const LabelFormInputWrapper = styled.div<{
  marginTop: string;
  marginBottom: string;
}>`
  display: flex;
  flex-direction: column;
  gap: 8px;
  margin-top: ${(props) => props.marginTop};
  margin-bottom: ${(props) => props.marginBottom};
`;

interface LabelFormInputProps {
  labelValues: OptionType[];
  setLabelValues: (labels: Partial<Label>[]) => void;
  marginTop?: string;
  marginBottom?: string;
  openOnInit?: boolean;
}

/**
 * LabelFormInput component.
 *
 * @component
 * @param labelValues - Array of label values.
 * @param marginBottom - Margin bottom value.
 * @param marginTop - Margin top value.
 * @param openOnInit - Flag indicating if the dropdown should open on initialization.
 * @param setLabelValues - Sends selected values to parent component
 * @returns {JSX.Element} LabelFormInput component.
 */
const LabelFormInput = ({
  labelValues = [],
  marginBottom = '0px',
  marginTop = '8px',
  openOnInit = false,
  setLabelValues,
}: LabelFormInputProps): JSX.Element => {
  const client = useApolloClient();

  const { data, loading, error } = useQuery(LABELS_MINIMAL_QUERY, {
    client,
    variables: { limit: 100 },
  });

  let inputError = '';
  let labels: OptionType[] = [];

  if (data?.labels?.edges.length) {
    labels = data.labels.edges.map((edgeNode: { node: Label }) => ({
      text: edgeNode.node.name,
      value: edgeNode.node.id,
      color: edgeNode.node.color,
    }));
  }

  if (error) inputError = i18n.t('announcements-Announcements-error');

  return (
    <LabelFormInputWrapper marginTop={marginTop} marginBottom={marginBottom}>
      <ChipSelect
        dataTestId="label-EditCustomerContactDetails-labelSelector"
        label={i18n.t('settings-Label-labels', {
          defaultValue: 'Labels',
        })}
        onChange={(val) => setLabelValues(val as unknown as Partial<Label>[])}
        placeholder={i18n.t('settings-Label-searchLabels', {
          defaultValue: 'Search Labels',
        })}
        loading={loading}
        options={labels}
        value={labelValues}
        error={inputError}
        openOnInit={openOnInit}
      />
    </LabelFormInputWrapper>
  );
};

export default LabelFormInput;
